import React, { memo, FC, useState } from "react";
import { EdgeProps, getBezierPath, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { useKeypressDelete } from "../Hooks/useKeypressDelete";
import { forceNodeUpdate } from "../Util/NodeUtil";
// import { validateConnectedEdge } from "../PlexFlow"; TODO REMOVE
import "./SwitchEdge.scss";
import {
  checkAndRemoveUnmappable,
  resetReferenceSchemas,
  updateEdgeValidationStyling,
  updateLinkedSchemas
} from "../Util/EdgeUtil";

const SwitchEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd
}) => {
  const foreignObjectSize = 30;
  const reactFlowInstance = useReactFlow();
  const [isOver, setIsOver] = useState(false);
  const updateNodeInternals = useUpdateNodeInternals();

  const onEdgeClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    evt.stopPropagation();
    const currentEdge = reactFlowInstance.getEdge(id);

    if (currentEdge) {
      checkAndRemoveUnmappable(
        [{ edge: currentEdge, newSchema: undefined }],
        (newEdges) => {
          forceNodeUpdate(reactFlowInstance.getNode(currentEdge?.target ?? ""), updateNodeInternals, reactFlowInstance);
          forceNodeUpdate(reactFlowInstance.getNode(currentEdge?.source ?? ""), updateNodeInternals, reactFlowInstance);

          const currentEdges = newEdges.filter(function (obj) {
            return obj.id !== id;
          });

          reactFlowInstance.setEdges(currentEdges);

          resetReferenceSchemas([currentEdge], reactFlowInstance, updateNodeInternals);
          updateLinkedSchemas(reactFlowInstance.getNodes(), currentEdges, updateNodeInternals, reactFlowInstance);
          updateEdgeValidationStyling();
        },
        reactFlowInstance,
        updateNodeInternals
      );
    }
  };

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  useKeypressDelete(id, isOver, false);

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="plex-fd-edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div className="plex-fd-edgebutton-container">
          <div onMouseEnter={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)}>
            <button className="plex-fd-edgebutton" onClick={(event) => onEdgeClick(event, id)}>
              ×
            </button>
          </div>
        </div>
      </foreignObject>
    </>
  );
};

export default memo(SwitchEdge);
