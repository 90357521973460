export interface IDocumentFlowInput {
  name: string;
  schema: string;
  required: boolean;
}

export interface IDocumentFlowOutput {
  name: string;
  schema: string;
}

export interface IDocumentNodePosition {
  x: number;
  y: number;
}

export interface IDocumentDesignerNode {
  position: IDocumentNodePosition;
  dockedNodeIds: string[];
}

export interface IDocumentNode {
  id: string;
  nodeType: string;
  name?: string;
  designerProperties: IDocumentDesignerNode;
}

export interface IDocumentNodeConnection {
  type: string;
  sourceId: string;
  sourceName: string;
  targetId: string;
  targetName: string;
}

export enum DocumentNodeConnectionType {
  control = "NODE_CONTROL",
  data = "NODE_DATA"
}

export interface IDocumentControlInput {
  name: string;
}

export interface IDocumentControlOutput {
  name: string;
}

export interface IDocumentDesignerDataInput {
  enabled: boolean;
}

export interface IDocumentDataInput {
  name: string;
  schema: string;
  required: boolean;
  designerProperties: IDocumentDesignerDataInput;
  label?: string;
}

export interface IDocumentDesignerDataOutput {
  enabled: boolean;
}

export interface IDocumentDataOutput {
  name: string;
  schema: string;
  designerProperties: IDocumentDesignerDataOutput;
  label?: string;
}

export enum DocumentSchemaType {
  primitive = "PRIMITIVE",
  object = "OBJECT",
  list = "LIST"
}

export interface IDocumentSchema {
  id: string;
  schemaType: DocumentSchemaType;
}

export interface IDocumentListSchema extends IDocumentSchema {
  listItemSchema: string;
}

export enum ObjectSchemaTags {
  standardObjectReference = "STANDARD_OBJECT_REFERENCE",
  customObjectReference = "CUSTOM_OBJECT_REFERENCE"
}

export enum ObjectSchemaPropertyTags {
  identifier = "IDENTIFIER"
}

export interface IDocumentObjectSchemaProperty {
  schema: string;
  required: boolean;
  label?: string;
  tags?: ObjectSchemaPropertyTags[];
}

export interface IDocumentObjectSchema extends IDocumentSchema {
  properties: { [name: string]: IDocumentObjectSchemaProperty };
  metadata?: any;
  tags?: ObjectSchemaTags[];
}

export const isReferenceSchema = (schema: IDocumentSchema) => {
  const objectSchema = schema as IDocumentObjectSchema;
  return objectSchema?.tags?.some(
    (tag: ObjectSchemaTags) =>
      tag === ObjectSchemaTags.standardObjectReference || tag === ObjectSchemaTags.customObjectReference
  );
};

export const getIdentifierName = (schema: IDocumentSchema) => {
  const objectSchema = schema as IDocumentObjectSchema;

  if (!objectSchema?.properties) {
    throw new Error("Cannot get identifier from a schema without properties.");
  }

  const identifierName = Object.keys(objectSchema?.properties!).find(
    (propertyName: string) =>
      objectSchema?.properties![propertyName]?.tags?.some(
        (t: ObjectSchemaPropertyTags) => t === ObjectSchemaPropertyTags.identifier
      )
  );
  return identifierName;
};

export const getIncludedFieldName = (schema: IDocumentSchema) => {
  const objectSchema = schema as IDocumentObjectSchema;

  if (!objectSchema?.properties) {
    throw new Error("Cannot get included fields from a schema without properties.");
  }

  const includedFieldName = Object.keys(objectSchema?.properties!).find(
    (propertyName: string) =>
      !objectSchema?.properties![propertyName]?.tags?.some(
        (t: ObjectSchemaPropertyTags) => t === ObjectSchemaPropertyTags.identifier
      )
  );
  return includedFieldName;
};

export enum DocumentPrimitiveType {
  boolean = "BOOLEAN",
  date = "DATE",
  dateTime = "DATE_TIME",
  decimal = "DECIMAL",
  integer = "INTEGER",
  string = "STRING",
  time = "TIME",
  uuid = "UUID"
}

export interface IDocumentPrimitiveSchema extends IDocumentSchema {
  primitiveType: DocumentPrimitiveType;
}

export interface IFlowDocumentModel {
  inputs: IDocumentFlowInput[];
  outputs: IDocumentFlowOutput[];
  nodes: IDocumentNode[];
  connections: IDocumentNodeConnection[];
  schemas: IDocumentSchema[];
}

export enum NumberArithmeticOperation {
  Add = "ADD",
  Subtract = "SUBTRACT",
  Multiply = "MULTIPLY",
  Divide = "DIVIDE",
  Modulus = "MODULUS"
}

export interface IFlowMetaData {
  name: string;
  summary: string;
}

export enum StringCompareOperation {
  Equal = "EQUAL",
  NotEqual = "NOT_EQUAL",
  Contains = "CONTAINS",
  StartsWith = "STARTS_WITH",
  EndsWith = "ENDS_WITH"
}

export enum NumberCompareOperation {
  Equal = "EQUAL",
  NotEqual = "NOT_EQUAL",
  Less = "LESS",
  LessOrEqual = "LESS_EQUAL",
  Greater = "GREATER",
  GreaterOrEqual = "GREATER_EQUAL"
}

export enum TimeUnit {
  Days = "DAYS",
  Hours = "HOURS",
  Minutes = "MINUTES",
  Months = "MONTHS",
  Weeks = "WEEKS",
  Years = "YEARS"
}

export enum DatePart {
  Second = "SECOND",
  Minute = "MINUTE",
  Hour = "HOUR",
  Day = "DAY",
  DayOfWeek = "DAY_OF_WEEK",
  Month = "MONTH",
  Year = "YEAR"
}

export enum TimeSpanUnit {
  Second = "SECOND",
  Minute = "MINUTE",
  Hour = "HOUR",
  Day = "DAY"
}

export type CompareOperation = StringCompareOperation | NumberCompareOperation;

export enum DateRangePeriod {
  Today = "TODAY",
  Yesterday = "YESTERDAY",
  Tomorrow = "TOMORROW",
  CurrentMonth = "CURRENT_MONTH",
  CurrentQuarter = "CURRENT_QUARTER",
  CurrentWeek = "CURRENT_WEEK",
  CurrentYear = "CURRENT_YEAR",
  PreviousMonth = "PREVIOUS_MONTH",
  PreviousQuarter = "PREVIOUS_QUARTER",
  PreviousWeek = "PREVIOUS_WEEK",
  PreviousYear = "PREVIOUS_YEAR",
  NextMonth = "NEXT_MONTH",
  NextQuarter = "NEXT_QUARTER",
  NextWeek = "NEXT_WEEK",
  NextYear = "NEXT_YEAR",
  Last7Days = "LAST_7_DAYS",
  Last30Days = "LAST_30_DAYS",
  Last60Days = "LAST_60_DAYS",
  Last90Days = "LAST_90_DAYS",
  MonthToDate = "MONTH_TO_DATE",
  QuarterToDate = "QUARTER_TO_DATE",
  WeekToDate = "WEEK_TO_DATE",
  YearToDate = "YEAR_TO_DATE"
}

export enum FirstDayOfWeek {
  Sunday = "SUNDAY",
  Monday = "MONDAY"
}
