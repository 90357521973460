import { IFlowDocumentModel } from "../FlowDocument/FlowDocumentModel";
import { base64FromArrayBuffer, base64FromString, base64ToArrayBuffer, base64ToString } from "../FlowDocument/base64";

type EncodingAlgorithm = "NONE" | "GZIP";
type ContentType = "FLOW_DOCUMENT";

interface IEncodingHeader {
  type: ContentType;
  ver?: string;
  comp: EncodingAlgorithm;
}

export const compress = (string: string, encoding: CompressionFormat) => {
  const byteArray = new TextEncoder().encode(string);
  const cs = new CompressionStream(encoding);
  const writer = cs.writable.getWriter();
  writer.write(byteArray);
  writer.close();
  return new Response(cs.readable).arrayBuffer();
};

export const decompress = (byteArray: ArrayBuffer, encoding: CompressionFormat) => {
  const cs = new DecompressionStream(encoding);
  const writer = cs.writable.getWriter();
  writer.write(byteArray);
  writer.close();
  return new Response(cs.readable).arrayBuffer().then(function (arrayBuffer) {
    return new TextDecoder().decode(arrayBuffer);
  });
};

export const encodeDocument = (document: IFlowDocumentModel) => {
  const flowDocumentString = JSON.stringify(document);
  return compress(flowDocumentString, "gzip").then((buffer: ArrayBuffer) => {
    let compressedDocumentString = base64FromArrayBuffer(buffer, true);

    const header: IEncodingHeader = {
      type: "FLOW_DOCUMENT",
      comp: "GZIP"
    };
    const headerString = JSON.stringify(header);
    const base64Header = base64FromString(headerString, true);
    const base64Content = compressedDocumentString;

    return `${base64Header}.${base64Content}`;
  });
};

export const decodeDocument = (encodedDocument: string) => {
  const hasEncodedHeader = encodedDocument[0] !== "{";
  let decodedHeader: IEncodingHeader | undefined;
  let buffer: ArrayBuffer = new Uint8Array() as unknown as ArrayBuffer;
  let tokenParts: string[] = [];
  if (hasEncodedHeader) {
    try {
      tokenParts = encodedDocument.split(".");
      const headerPart = tokenParts[0]!;
      const headerString = base64ToString(headerPart, true);
      decodedHeader = JSON.parse(headerString);
      buffer = base64ToArrayBuffer(tokenParts[1]!, true);
    } catch (_error) {
      console.log("No header found.");
    }

    switch (decodedHeader?.comp) {
      case "GZIP":
        return decompress(buffer, "gzip").then((decompressedString: string) => {
          return JSON.parse(decompressedString) as IFlowDocumentModel;
        });
      case "NONE":
        const jsonString = base64ToString(tokenParts[1] ?? "", true);
        return Promise.resolve(JSON.parse(jsonString) as unknown as IFlowDocumentModel);
      default:
        return Promise.reject(new Error("Encoding not supported"));
    }
  } else {
    return Promise.resolve(JSON.parse(encodedDocument) as unknown as IFlowDocumentModel);
  }
};
