import React, { FC } from "react";
import { BasicNodeLayout, NodeSize, NodeTypeCategory, ReferencePropertyType } from "../Base";
import { NodeProps } from "reactflow";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { noReferenceSchemaId } from "../DataSchemas";

const referenceInputName = "reference";
const keyValueOutputName = "keyValue";
const includedFieldOutputName = "includedField";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConvertReferenceToPropertiesNode",
  label: "Reference Data",
  category: NodeTypeCategory.transformations,
  hideLabelOnAnchor: true,
  isExperimental: true,
  controlOutputs: [],
  // When schemaId is no reference, it will adapt to the connected reference schema upon connection.
  dataInputs: [{ name: referenceInputName, type: DataType.OBJECTREFERENCE, schemaId: noReferenceSchemaId }],
  dataOutputs: [
    { name: keyValueOutputName, label: "Key", type: DataType.INTEGER },
    { name: includedFieldOutputName, type: DataType.STRING, enabled: false }
  ],
  nodeConfigProperties: [
    {
      name: "outputs",
      sectionName: "Outputs",
      propertyType: NodeConfigPropertyType.EnabledOutputs
    },
    {
      name: "keySchema",
      label: "Key Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        // Data type will adapt to the reference schema upon reference handle connection
        dataProperties: [referenceInputName, keyValueOutputName],
        dataTypes: [DataType.INTEGER, DataType.STRING, DataType.UUID],
        referenceProperty: ReferencePropertyType.identifier
      }
    },
    {
      name: "includedFieldSchema",
      label: "Included Field Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        // Data type will adapt to the reference schema upon reference handle connection
        dataProperties: [referenceInputName, includedFieldOutputName],
        referenceProperty: ReferencePropertyType.includedField
      }
    }
  ],
  size: NodeSize.Small,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const ConvertReferenceToPropertiesNode: FC<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
