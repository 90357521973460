import { Node, ReactFlowInstance, UpdateNodeInternals } from "reactflow";
import { DocumentSchemaSource, IDataParam, INodeProperty, INodeTypeDefinition, IoType } from "./Base";
import {
  addObjectListSchema,
  addObjectReferenceSchema,
  addObjectSchema,
  addSchemas,
  getDataTypeFromSchema,
  getDocumentObjectSchemaFromProperties,
  getEmptySchemaId,
  getObjectSchemaIdWithSource,
  getSchemaId,
  noReferenceSchemaId,
  setInputsFromSchema
} from "./DataSchemas";
import { DataType, dataTypes, isObjectLike } from "./TypeDefinitions";
import { IBannerContext, BannerStatus } from "@plex/react-components";
import { updateLinkedSchemas } from "../Util/EdgeUtil";
import { forceNodeUpdate } from "../Util/NodeUtil";
import {
  DocumentSchemaType,
  IDocumentDataInput,
  IDocumentDataOutput,
  IDocumentObjectSchema,
  IDocumentSchema,
  ObjectSchemaTags
} from "../FlowDocument/FlowDocumentModel";
import { upperSnakeToCamelCase } from "../FlowDocument/DocumentProcessor";
import { convertKeysToCamelCaseNested } from "../Util/KeyFormatter";

export interface IStandardObject {
  id: string;
  name: string;
  identifiers: IDataParam[];
  fields: IDataParam[];
  filters: IDataParam[];
  searchResult: IDataParam[];
}

export interface INodeMetadata {
  inputs: IDocumentDataInput[];
  outputs: IDocumentDataOutput[];
  schemas: IDocumentSchema[];
}

interface IAddIo {
  newPropertyValue?: { id: string; friendlyName: string };
  node: Node<any>;
  propertyName: string;
  schemaSourceType: DocumentSchemaSource;
  ioType: IoType;
  standardObject: IStandardObject;
  reactFlowInstance: ReactFlowInstance;
  updateNodeInternals: UpdateNodeInternals;
}

export const getStandardObjectSchemaIds = (node: Node<any>) => {
  return [
    getSchemaId(DataType.OBJECT, node, ["identifiers"]),
    getSchemaId(DataType.OBJECT, node, ["filters"]),
    getSchemaId(DataType.OBJECT, node, ["fields"]),
    getSchemaId(DataType.OBJECT, node, ["searchResult"]),
    getSchemaId(DataType.OBJECTLIST, node, ["searchResult"])
  ];
};

export interface ICreateIoParam {
  newPropertyValue?: { id: string; friendlyName: string };
  standardObject?: IStandardObject;
  node: Node<any>;
  schemaSystemProperty: INodeProperty;
  bannerContext: IBannerContext;
  plexGetCustomFieldsSchema:
    | ((standardObjectId: string, standardObjectFriendlyName: string) => Promise<any>)
    | undefined;
  plexShowOverlay: ((status: boolean) => void) | undefined;
  reactFlowInstance: ReactFlowInstance;
  updateNodeInternals: UpdateNodeInternals;
}

export const createIo = ({
  standardObject,
  node,
  schemaSystemProperty,
  reactFlowInstance,
  updateNodeInternals
}: ICreateIoParam) => {
  const id = node.data.nodeProperties[schemaSystemProperty.name];

  if (!id) {
    resetIo(node, reactFlowInstance, updateNodeInternals);
    return;
  }

  switch (schemaSystemProperty.schemaSourceSystemType) {
    case DocumentSchemaSource.customFieldsV1:
      if (standardObject && schemaSystemProperty.schemaSystem?.ioType !== undefined) {
        addCustomFieldsIoV1({
          node,
          propertyName: schemaSystemProperty.name,
          schemaSourceType: schemaSystemProperty.schemaSourceSystemType!,
          ioType: schemaSystemProperty.schemaSystem.ioType,
          standardObject: standardObject,
          reactFlowInstance,
          updateNodeInternals
        });
      }
      break;
  }
};

export interface ILoadCustomFieldsResult {
  outputSchema: IDocumentSchema;
  standardObject: IStandardObject;
}

const getFakeSchema = () => {
  return {
    Identifiers: [
      {
        Key: "IDENTIFIER_VALUE__Triangle_Id",
        Name: "Triangle_Id",
        PrimitiveType: undefined,
        ObjectReference: {
          Name: "Triangle_Id",
          PrimitiveType: "UUID",
          Label: "Triangle"
        },
        FriendlyName: "Triangle"
      }
    ],
    Fields: [
      {
        Key: "FIELD_VALUE__System_Count",
        Name: "System_Count",
        PrimitiveType: "INTEGER",
        FriendlyName: "System Count"
      },
      {
        Key: "FIELD_VALUE__Other_Triangle_Id",
        Name: "Other_Triangle_Id",
        PrimitiveType: undefined,
        ObjectReference: {
          Name: "Triangle_Id",
          PrimitiveType: "UUID",
          Label: "Triangle"
        },
        FriendlyName: "Other Triangle"
      },
      {
        Key: "FIELD_VALUE__Other_Square_Id",
        Name: "Other_Square_Id",
        PrimitiveType: undefined,
        ObjectReference: {
          Name: "Square_Id",
          PrimitiveType: "INTEGER",
          Label: "Square"
        },
        FriendlyName: "Other Square"
      }
    ],
    Filters: []
  };
};

const toDataParam = (
  schemaSystemProperty: INodeProperty,
  id: string,
  soParam: {
    id: string;
    key: string;
    primitiveType: string;
    objectReference: { Name: string; PrimitiveType: string; Label: string };
    friendlyName: string;
  }
): IDataParam => {
  let schemaId: string | undefined = undefined;
  const isObjectReference = soParam.objectReference ? true : false;
  if (isObjectReference) {
    const schemaSource: {
      sourceSystem: DocumentSchemaSource;
      sourceId: any;
    } = { sourceSystem: DocumentSchemaSource.customFieldsV1, sourceId: id };
    schemaId = getObjectSchemaIdWithSource(
      DataType.OBJECTREFERENCE,
      [DataType.OBJECTREFERENCE + "_" + soParam.objectReference.Name],
      schemaSource
    );
    const objectReferenceSchema: IDocumentObjectSchema = {
      id: schemaId,
      schemaType: DocumentSchemaType.object,
      properties: {},
      tags: [ObjectSchemaTags.standardObjectReference]
    };
    objectReferenceSchema.properties[soParam.objectReference.Name] = {
      schema: soParam.objectReference.PrimitiveType,
      label: soParam.objectReference.Label,
      required: true
    };
    addObjectReferenceSchema(objectReferenceSchema, schemaSystemProperty.schemaSourceSystemType!, id);
  } else {
    schemaId = upperSnakeToCamelCase(soParam.primitiveType);
  }
  return {
    name: soParam.key,
    type: isObjectReference ? DataType.OBJECTREFERENCE : (upperSnakeToCamelCase(soParam.primitiveType) as DataType),
    schemaId: schemaId,
    label: soParam.friendlyName
  };
};

export const loadIo = ({
  newPropertyValue,
  node,
  schemaSystemProperty,
  bannerContext,
  plexGetCustomFieldsSchema,
  plexShowOverlay,
  reactFlowInstance,
  updateNodeInternals
}: ICreateIoParam) => {
  let promise: Promise<any | undefined | void> = Promise.resolve(undefined);
  const id = newPropertyValue!.id;
  const friendlyName = newPropertyValue!.friendlyName;

  if (schemaSystemProperty) {
    plexShowOverlay && plexShowOverlay(true);
    switch (schemaSystemProperty.schemaSourceSystemType) {
      case DocumentSchemaSource.customFieldsV1:
        if (plexGetCustomFieldsSchema) {
          // promise = plexGetCustomFieldsSchema(id, friendlyName)
          //   .then((res) => {
          //HACK: Use fake record to test object references.
          const fakeSchema = getFakeSchema();
          const fakeSoResponse = {
            Data: {
              Schema: JSON.stringify(fakeSchema)
            }
          };
          if (id === "fakeSoWithReference") {
            promise = Promise.resolve(fakeSoResponse);
            plexShowOverlay && plexShowOverlay(false);
          } else {
            promise = plexGetCustomFieldsSchema(id, friendlyName);
          }
          promise = promise
            .then((res) => {
              const result: any = JSON.parse(res.Data.Schema);
              const resultData: any = convertKeysToCamelCaseNested(result);
              const standardObject: IStandardObject = {
                id: id,
                name: friendlyName,
                identifiers: resultData.identifiers
                  .filter((param: any) => param.primitiveType || param.objectReference)
                  .map((param: any) => toDataParam(schemaSystemProperty, id, param)),
                fields: resultData.fields
                  .filter((param: any) => param.primitiveType || param.objectReference)
                  .map((param: any) => toDataParam(schemaSystemProperty, id, param)),
                filters: resultData.filters
                  .filter((param: any) => param.primitiveType || param.objectReference)
                  .map((param: any) => toDataParam(schemaSystemProperty, id, param)),
                searchResult: resultData.identifiers
                  .concat(resultData.fields)
                  .filter((param: any) => param.primitiveType || param.objectReference)
                  .map((param: any) => toDataParam(schemaSystemProperty, id, param))
              };
              if (schemaSystemProperty.schemaSystem?.ioType !== undefined) {
                const outputSchema = addCustomFieldsSchemaV1({
                  newPropertyValue,
                  node,
                  propertyName: schemaSystemProperty.name,
                  schemaSourceType: schemaSystemProperty.schemaSourceSystemType!,
                  ioType: schemaSystemProperty.schemaSystem.ioType,
                  standardObject: standardObject,
                  reactFlowInstance,
                  updateNodeInternals
                });
                return Promise.resolve({
                  outputSchema: outputSchema,
                  standardObject: standardObject
                } as ILoadCustomFieldsResult);
              }

              plexShowOverlay && plexShowOverlay(false);
            })
            .catch((e) => {
              bannerContext.addMessage("An error occurred retrieving custom fields metadata.", BannerStatus.error);
              console.log(e);

              plexShowOverlay && plexShowOverlay(false);
            });
        }
    }
  }

  return promise;
};

const getDesignerDataParams = (documentDataInputs: (IDocumentDataInput | IDocumentDataOutput)[]) => {
  return documentDataInputs.map((documentDataInput: IDocumentDataInput) => {
    const camelCaseSchema = upperSnakeToCamelCase(documentDataInput.schema);
    const primitiveSchema = dataTypes.find((dt: string) => dt === camelCaseSchema);
    return {
      name: documentDataInput.name ?? "",
      type: primitiveSchema ?? getDataTypeFromSchema(documentDataInput.schema),
      schemaId: primitiveSchema ?? documentDataInput.schema,
      required: documentDataInput.required === true,
      label: documentDataInput.label,
      enabled: documentDataInput.designerProperties.enabled,
      hideLabel: (documentDataInput as any).hideLabel
    } as IDataParam;
  });
};

export const loadNodeMetadata = (
  node: Node<any>,
  meta: INodeMetadata,
  nodeConfigProperty: INodeProperty,
  updateNodeInternals: UpdateNodeInternals,
  reactFlowInstance: ReactFlowInstance
) => {
  const schemaSystem = nodeConfigProperty.schemaSourceSystemType as string;
  const schemaSystemId = node.data.nodeProperties[nodeConfigProperty.name] as string;

  node!.data.nodeProperties.inputs = {};

  addSchemas(meta.schemas, schemaSystem, schemaSystemId);
  const designerDataInputs = getDesignerDataParams(meta.inputs);
  designerDataInputs.forEach((input: IDataParam) => {
    node.data.nodeProperties.inputs[input.name] = { ...input };
  });
  const designerDataOutputs = getDesignerDataParams(meta.outputs);
  designerDataOutputs.forEach((output: IDataParam) => {
    node.data.nodeProperties.outputs[output.name] = { ...output };
  });

  updateLinkedSchemas(
    reactFlowInstance.getNodes(),
    reactFlowInstance.getEdges(),
    updateNodeInternals,
    reactFlowInstance
  );
  forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
};

export const resetIo = (
  node: Node<any>,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;

  Object.keys(node!.data.nodeProperties.inputs).forEach((inputName: string) => {
    const isDefinedObjectInput = nodeDefinition.dataInputs.some(
      (input: IDataParam) =>
        (input.name === inputName && input.type === DataType.OBJECTREFERENCE) || isObjectLike(input.type)
    );
    if (!isDefinedObjectInput) {
      delete node!.data.nodeProperties.inputs[inputName];
    }
  });

  Object.keys(node!.data.nodeProperties.outputs).forEach((outputName: string) => {
    const isDefinedObjectOutput = nodeDefinition.dataOutputs.some(
      (output: IDataParam) =>
        (output.name === outputName && output.type === DataType.OBJECTREFERENCE) || isObjectLike(output.type)
    );
    if (!isDefinedObjectOutput) {
      delete node!.data.nodeProperties.outputs[outputName];
    }
  });

  nodeDefinition.dataInputs.forEach((dataInput: IDataParam) => {
    if (isObjectLike(dataInput.type)) {
      node!.data.nodeProperties.inputs[dataInput.name].schemaId = getEmptySchemaId(
        node!.data.nodeProperties.inputs[dataInput.name].type
      );
    }

    if (dataInput.type === DataType.OBJECTREFERENCE) {
      node!.data.nodeProperties.inputs[dataInput.name].schemaId = noReferenceSchemaId;
    }
  });

  nodeDefinition.dataOutputs.forEach((dataOutput: IDataParam) => {
    if (isObjectLike(dataOutput.type)) {
      node!.data.nodeProperties.outputs[dataOutput.name].schemaId = getEmptySchemaId(
        node!.data.nodeProperties.outputs[dataOutput.name].type
      );
    }

    if (dataOutput.type === DataType.OBJECTREFERENCE) {
      node!.data.nodeProperties.outputs[dataOutput.name].schemaId = noReferenceSchemaId;
    }
  });

  updateLinkedSchemas(
    reactFlowInstance.getNodes(),
    reactFlowInstance.getEdges(),
    updateNodeInternals,
    reactFlowInstance
  );
  setTimeout(() => forceNodeUpdate(node, updateNodeInternals, reactFlowInstance), 80);
};

const addCustomFieldsIoV1 = ({
  node,
  propertyName,
  ioType,
  standardObject,
  reactFlowInstance,
  updateNodeInternals
}: IAddIo) => {
  resetIo(node, reactFlowInstance, updateNodeInternals);

  if (node.data.nodeProperties[propertyName]) {
    const nodeDefinition: INodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;

    let outputName: string | undefined;
    const schemaSourceId: string = node.data.nodeProperties.standardObjectId;
    const schemaSource: {
      sourceSystem: DocumentSchemaSource;
      sourceId: any;
    } = { sourceSystem: DocumentSchemaSource.customFieldsV1, sourceId: schemaSourceId };

    const identifiersSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["identifiers"], schemaSource);
    const filtersSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["filters"], schemaSource);
    const fieldsSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["fields"], schemaSource);
    const searchResultIdPart = "searchResult";
    const searchResultListSchemaId = getObjectSchemaIdWithSource(
      DataType.OBJECTLIST,
      [searchResultIdPart],
      schemaSource
    );

    const identifiersSchema = getDocumentObjectSchemaFromProperties(identifiersSchemaId, standardObject.identifiers);
    const filtersSchema = getDocumentObjectSchemaFromProperties(filtersSchemaId, standardObject.filters);
    const fieldsSchema = getDocumentObjectSchemaFromProperties(fieldsSchemaId, standardObject.fields);

    switch (ioType) {
      case IoType.get:
        setInputsFromSchema(node, identifiersSchema, true);
        outputName = nodeDefinition.dataOutputs[0]?.name;
        if (outputName) {
          node.data.nodeProperties.outputs[outputName].schemaId = fieldsSchemaId;
        }
        break;
      case IoType.search:
        setInputsFromSchema(node, filtersSchema);
        outputName = nodeDefinition.dataOutputs[0]?.name;
        if (outputName) {
          node.data.nodeProperties.outputs[outputName].schemaId = searchResultListSchemaId;
        }
        break;
      case IoType.update:
        setInputsFromSchema(node, identifiersSchema, true);
        setInputsFromSchema(node, fieldsSchema);
        break;
    }
  }

  updateLinkedSchemas(
    reactFlowInstance.getNodes(),
    reactFlowInstance.getEdges(),
    updateNodeInternals,
    reactFlowInstance
  );
  setTimeout(() => forceNodeUpdate(node, updateNodeInternals, reactFlowInstance), 80);
};

const addCustomFieldsSchemaV1 = ({ newPropertyValue, node, propertyName, ioType, standardObject }: IAddIo) => {
  let outputSchema: IDocumentSchema | undefined = undefined;
  if (newPropertyValue?.id) {
    const nodeDefinition: INodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;

    const standardObjectProperty: INodeProperty = nodeDefinition.nodeConfigProperties.filter(
      (p: INodeProperty) => p.name === propertyName
    )[0]!;
    const schemaSourceSystem: string | undefined = standardObjectProperty.schemaSourceSystemType;
    const schemaSourceId: string = newPropertyValue?.id;
    const schemaSource: {
      sourceSystem: DocumentSchemaSource;
      sourceId: any;
    } = { sourceSystem: DocumentSchemaSource.customFieldsV1, sourceId: schemaSourceId };

    const identifiersSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["identifiers"], schemaSource);
    const filtersSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["filters"], schemaSource);
    const fieldsSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["fields"], schemaSource);
    const searchResultIdPart = "searchResult";
    const searchResultSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, [searchResultIdPart], schemaSource);
    const searchResultListSchemaId = getObjectSchemaIdWithSource(
      DataType.OBJECTLIST,
      [searchResultIdPart],
      schemaSource
    );

    const identifiersSchema = getDocumentObjectSchemaFromProperties(identifiersSchemaId, standardObject.identifiers);
    const filtersSchema = getDocumentObjectSchemaFromProperties(filtersSchemaId, standardObject.filters);
    const fieldsSchema = getDocumentObjectSchemaFromProperties(fieldsSchemaId, standardObject.fields);
    const searchResultSchema = getDocumentObjectSchemaFromProperties(searchResultSchemaId, standardObject.searchResult);

    const searchResultSchemaList = {
      id: searchResultListSchemaId,
      schemaType: DocumentSchemaType.list,
      listItemSchema: searchResultSchemaId
    };

    switch (ioType) {
      case IoType.get:
        addObjectSchema(identifiersSchema, schemaSourceSystem!, schemaSourceId);
        addObjectSchema(fieldsSchema, schemaSourceSystem!, schemaSourceId);
        outputSchema = fieldsSchema;
        break;
      case IoType.search:
        addObjectSchema(filtersSchema, schemaSourceSystem!, schemaSourceId);
        addObjectSchema(searchResultSchema, schemaSourceSystem!, schemaSourceId);
        addObjectListSchema(searchResultSchemaList, schemaSourceSystem!, schemaSourceId);
        outputSchema = searchResultSchemaList;
        break;
      case IoType.update:
        addObjectSchema(identifiersSchema, schemaSourceSystem!, schemaSourceId);
        addObjectSchema(fieldsSchema, schemaSourceSystem!, schemaSourceId);
        break;
    }
  }

  return outputSchema;
};
