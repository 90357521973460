import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, DocumentSchemaSource, IoType, NodeSize, NodeTypeCategory } from "../Base";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CallCustomObjectSearchNode",
  label: "Search Custom Object",
  category: NodeTypeCategory.services,
  isExperimental: true,
  size: NodeSize.Small,
  controlInputs: [{ name: "call" }],
  controlOutputs: [{ name: "completed", label: "" }],
  documentDataInputListName: "filters",
  dataInputs: [],
  dataOutputs: [{ name: "results", type: DataType.OBJECTLIST }],
  nodeConfigProperties: [
    {
      name: "customObjectId",
      label: "Custom Object",
      propertyType: NodeConfigPropertyType.CustomObject,
      schemaSourceSystemType: DocumentSchemaSource.customObject,
      schemaSystem: { createIo: true, ioType: IoType.search }
    },
    {
      name: "customObjectName",
      label: "Custom Object Name",
      propertyType: NodeConfigPropertyType.NoInput,
      schemaSourceSystemType: DocumentSchemaSource.customObject
    },
    {
      name: "filters",
      sectionName: "Filters",
      propertyType: NodeConfigPropertyType.EnabledInputs
    }
  ],
  skipEvaluate: true,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const CallCustomObjectSearchNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  let label = "Search Custom Object";
  if (data.nodeProperties.customObjectName) {
    label = `Search ${data.nodeProperties.customObjectName} Custom Object`;
    data.nodeProperties.label = label;
  }
  return <BasicNodeLayout id={id} data={data} label={label} />;
};
