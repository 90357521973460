import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, DocumentSchemaSource, IoType, NodeSize, NodeTypeCategory } from "../Base";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { DataType } from "../TypeDefinitions";
import { noReferenceSchemaId } from "../DataSchemas";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CallCustomObjectUpdateNode",
  label: "Update Custom Object",
  category: NodeTypeCategory.services,
  isExperimental: true,
  size: NodeSize.Small,
  controlInputs: [{ name: "call" }],
  controlOutputs: [{ name: "completed", label: "" }],
  documentDataInputListName: "inputs",
  dataInputs: [{ name: "identifier", label: "ID", type: DataType.OBJECTREFERENCE, schemaId: noReferenceSchemaId }],
  dataOutputs: [],
  nodeConfigProperties: [
    {
      name: "customObjectId",
      label: "Custom Object",
      propertyType: NodeConfigPropertyType.CustomObject,
      schemaSourceSystemType: DocumentSchemaSource.customObject,
      schemaSystem: { createIo: true, ioType: IoType.update }
    },
    {
      name: "customObjectName",
      label: "Custom Object Name",
      propertyType: NodeConfigPropertyType.NoInput,
      schemaSourceSystemType: DocumentSchemaSource.customObject
    },
    {
      name: "fields",
      sectionName: "Fields",
      propertyType: NodeConfigPropertyType.EnabledInputs
    }
  ],
  skipEvaluate: true,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const CallCustomObjectUpdateNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  let label = "Update Custom Object";
  if (data.nodeProperties.customObjectName) {
    label = `Update ${data.nodeProperties.customObjectName} Custom Object`;
    data.nodeProperties.label = label;
  }
  return <BasicNodeLayout id={id} data={data} label={label} />;
};
