import React, { FunctionComponent } from "react";
import { IDataColumnDefProps, ITextQuery, PickerInput } from "@plex/react-components";
import { SourceAPI } from "../NodeTypes/TypeDefinitions";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import "./PickerFormField.scss";
import { allEdgesDeletionMode, deleteEdgesFromNodes } from "../Util/EdgeUtil";
import { useReactFlow, useUpdateNodeInternals } from "reactflow";

export interface ISourceApiPickerProps extends INodeConfigPropertiesProps {
  dialogTitle: string;
  displayName: string;
  searchFunction?: (query: string) => Promise<SourceAPI[]>;
}

export const SourceApiPickerFormField: FunctionComponent<ISourceApiPickerProps> = (props) => {
  let [{ pickerSelected, pickerData, searchText }, setPicker] = React.useState({
    pickerSelected: props.node?.data.nodeProperties[props.name]
      ? [
          {
            id: props.node?.data.nodeProperties[props.name],
            name: props.node?.data.nodeProperties[props.displayName]
          } as SourceAPI
        ]
      : ([] as SourceAPI[]),
    pickerData: [] as { id: string; name: string }[],
    searchText: ""
  });
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [, _setState] = React.useState({ value: props.node?.data.nodeProperties || {} });
  const [recordLimitExceededState, setRecordLimitExceededState] = React.useState(false);
  const [searchErrorState, setSearchErrorState] = React.useState("");

  const handlePickerSearch = (query: ITextQuery, cols: Array<IDataColumnDefProps<SourceAPI>>) => {
    if (props.searchFunction) {
      props
        .searchFunction(query?.displayText)
        .then((results: SourceAPI[]) => {
          setRecordLimitExceededState(false);
          setSearchErrorState("");

          let items: {
            id: string;
            name: string;
          }[];
          if (cols.length === 0) {
            items = results.filter((x) => (x.name || "").toLowerCase().includes(query.query.toLowerCase()));
          } else {
            items = results.filter((row: any) =>
              query.isMatch(cols.map((col) => (col.valueSelector ? String(col.valueSelector(row)) : "")))
            );
          }

          setPicker((x: any) => ({ ...x, pickerData: items }));
        })
        .catch((e) => {
          setSearchErrorState("An error occurred when searching.");
          setRecordLimitExceededState(false);
          console.error(e);
        });
    }
  };

  const handlePickerSearchTextChanged = (value: string) => {
    setPicker((x: any) => ({ ...x, searchText: value }));
  };

  return (
    <PickerInput<SourceAPI>
      key={props.dialogTitle + "Picker"}
      dialogTitle={props.dialogTitle}
      initialWidth={900}
      disabled={false}
      selected={pickerSelected}
      onSelectionChanged={(values: any) => {
        if (props.node!.data.nodeProperties[props.name] === values[0]?.id) {
          return;
        }

        ((props.beforeChange && props.beforeChange(values)) ?? Promise.resolve()).then(() => {
          deleteEdgesFromNodes([props.node!.id], reactFlowInstance, updateNodeInternals, {
            ...allEdgesDeletionMode,
            controlEdges: false,
            outputEdges: false
          });

          if (values.length === 0) {
            setPicker((x) => ({ ...x, pickerSelected: [], searchText: "" }));
            [];
            props.node!.data.nodeProperties[props.name] = null;
            props.node!.data.nodeProperties[props.displayName] = null;

            if (props.onChange) {
              props.onChange();
            }
            return;
          }

          pickerSelected = values.length > 0 ? [values[0]] : [];
          setPicker((x) => ({
            ...x,
            pickerSelected: pickerSelected,
            searchText: ""
          }));
          props.node!.data.nodeProperties[props.name] = values[0].id;
          props.node!.data.nodeProperties[props.displayName] = values[0].name;

          if (props.onChange) {
            props.onChange();
          }
        });
      }}
      keySelector={(row: SourceAPI) => row?.id}
      displaySelector={(row: SourceAPI) => row?.name}
      data={pickerData}
      onSearch={handlePickerSearch}
      searchDisabled={false}
      isOpen={false}
      multiSelect={false}
      maxDisplay={500}
      recordLimitExceeded={recordLimitExceededState}
      errorMessage={searchErrorState ? searchErrorState : undefined}
      searchText={searchText}
      onSearchTextChange={handlePickerSearchTextChanged}
    >
      <PickerInput.Column<SourceAPI>
        key="1"
        id={props.displayName}
        title="Name"
        valueSelector={(row: SourceAPI) => row.id}
      >
        {(row: SourceAPI) => row.name}
      </PickerInput.Column>
    </PickerInput>
  );
};
