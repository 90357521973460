import { camelCaseToSpacesRegEx } from "../../NodePropertiesForm/FormConstants";
import { AnchorArea } from "../../Util/AnchorUtil";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

/** The category a node type is defined by. */

export enum NodeTypeCategory {
  general,
  logic,
  services,
  lists,
  numbers,
  dates,
  text,
  transformations
}

export enum NodeSize {
  Normal = 200,
  Small = 160,
  Large = 250
}

export interface IDataParam {
  name: string;
  type: DataType;
  schemaId?: string;
  enabled?: boolean;
  required?: boolean;
  label?: string;
  hideLabel?: boolean;
}

export interface IEnumOptions {
  values: any;
}

export interface IDataPropertyCountOptions {
  cloneFrom: string;
}

export enum ReferencePropertyType {
  identifier,
  includedField
}

export interface IDataTypeOptions {
  dataTypes?: DataType[];
  dataProperties: string[];
  referenceProperty?: ReferencePropertyType;
}

export interface IStandardObjectPickerOptions {
  customFieldsOnly: boolean;
}

export enum IoType {
  get,
  search,
  update
}

export enum DocumentSchemaSource {
  customObject = "CUSTOM_OBJECT",
  dataSource = "DATA_SOURCE",
  plexOpenApi = "PLEX_OPEN_API",
  customFieldsV1 = "CUSTOM_FIELDS_V1"
}

export interface ISchemaSystem {
  createIo?: boolean;
  ioType?: IoType;
}

export type NodePropertyOptions =
  | IEnumOptions
  | IDataPropertyCountOptions
  | IDataTypeOptions
  | IStandardObjectPickerOptions;

export interface INodeProperty {
  name: string;
  label?: string;
  outsideFormEdit?: boolean;
  propertyType: NodeConfigPropertyType;
  sectionName?: string;
  schemaSourceSystemType?: DocumentSchemaSource;
  schemaSystem?: ISchemaSystem;
  options?: NodePropertyOptions;
}

export interface IControlFlowParam {
  name: string;
  label?: string;
  isError?: boolean;
  startsLoop?: boolean;
}

/** The definition for a node type. */
export interface INodeTypeDefinition {
  /** Name of the react component for this node type. */
  componentName: string;
  /** The unique id for this node type. */
  id: string;
  /** The {@link NodeTypeCategory} for this node type. */
  category: NodeTypeCategory;
  /** The order used to sort this node type in menus. */
  menuOrder: number;
  /** The label displayed for this node type, if different from the id. */
  label: string;
  /** A flag for if the label should show when anchored. */
  hideLabelOnAnchor: boolean;
  /** Which area node anchors to when no connection is made. */
  unconnectedAnchorLocation: AnchorArea | undefined;
  /** If there is a control input, but no control outputs. */
  controlInputOnly: boolean;
  /** The controlled flow order inputs for this node type. */
  controlInputs: IControlFlowParam[];
  /** If there is a control output, but no control inputs. */
  controlOutputOnly?: boolean;
  /** The controlled flow order outputs for this node type. */
  controlOutputs: IControlFlowParam[];
  /** If this node is only possible inside loops. */
  innerLoop: boolean;
  /** The data input {@link IDataParam} list for this node type on creation. */
  dataInputs: IDataParam[];
  /** The node returns an item from the list input. */
  listToItem: boolean;
  /** The list the data inputs are stored as in the document, or undefined if not stored as a list. */
  documentDataInputListName?: string;
  /** The data output {@link IDataParam} list for this node type on creation. */
  dataOutputs: IDataParam[];
  /** The list the data outputs are stored as in the document, or undefined if not stored as a list. */
  documentDataOutputList?: string;
  /** The outputs are the schema properties of input. */
  outputsSchemaProperties: boolean;
  /** The user provided configurations for a node prior to running a flow */
  nodeConfigProperties: INodeProperty[];
  /** The size of the node on the canvas. */
  size: NodeSize;
  /** If the evaluate function will be skipped during execution. Only use this for special case node types. */
  skipEvaluate: boolean;
  /** A flag that denote the given node is support for experimental mode*/
  isExperimental: boolean;
  /** A flag that denotes the given node should prompt the user to confirm their intention to delete the node.  */
  confirmDeletion: boolean;
  /** The evaluation performed when running this node. */
  evaluate: (
    input: { [name: string]: string | number | boolean | object | object[] },
    nodeProperties?: any
  ) => {
    output: { [name: string]: string | number | boolean | object | object[] };
    activeControlHandleIds: string[];
  };
}

/** The definition for a node type. */
export interface ISetNodeTypeDefinitionParams {
  /** Name of the react component for this node type. */
  componentName: string;
  /** The {@link NodeTypeCategory} for this node type. */
  category: NodeTypeCategory;
  /** The label displayed for this node type, if different from the id. */
  label?: string;
  /** A flag for if the label should show when anchored. */
  hideLabelOnAnchor?: boolean;
  /** Which area node anchors to when no connection is made. */
  unconnectedAnchorLocation?: AnchorArea | undefined;
  /** If there is a control input, but no control outputs. */
  controlInputOnly?: boolean;
  /** The controlled flow order inputs for this node type. */
  controlInputs?: IControlFlowParam[];
  /** If there is a control output, but no control inputs. */
  controlOutputOnly?: boolean;
  /** The controlled flow order outputs for this node type on creation. */
  controlOutputs: IControlFlowParam[];
  /** If this node is only possible inside loops. */
  innerLoop?: boolean;
  /** The data input {@link IDataParam} list for this node type on creation. */
  dataInputs: IDataParam[];
  /** The node returns an item from the list input. */
  listToItem?: boolean;
  /** The list the data inputs are stored as in the document, or undefined if not stored as a list. */
  documentDataInputListName?: string;
  /** The data output {@link IDataParam} list for this node type on creation. */
  dataOutputs: IDataParam[];
  /** The list the data outputs are stored as in the document, or undefined if not stored as a list. */
  documentDataOutputList?: string;
  /** The outputs are the schema properties of input. */
  outputsSchemaProperties?: boolean;
  /** The user provided configurations for a node prior to running a flow */
  nodeConfigProperties?: INodeProperty[];
  /** The size of the node on the canvas. */
  size?: NodeSize;
  /** If the evaluate function will be skipped during execution. Only use this for special case node types. */
  skipEvaluate?: boolean;
  /** A flag that denotes the given node is support for experimental mode*/
  isExperimental?: boolean;
  /** A flag that denotes the given node should prompt the user to confirm their intention to delete the node.  */
  confirmDeletion?: boolean;
  /** The evaluation performed when running this node. */
  evaluate: (
    input: { [name: string]: string | number | boolean | object | object[] },
    nodeProperties?: any
  ) => {
    output: { [name: string]: string | number | boolean | object | object[] };
    activeControlHandleIds: string[];
  };
}

export class NodeTypeDefinitions {
  private definitions: { [nodeTypeId: string]: INodeTypeDefinition };

  constructor() {
    this.definitions = {};
  }

  public getDefinition(id: string) {
    return this.definitions[id];
  }

  public getDefinitions() {
    return Object.keys(this.definitions).map((key) => this.definitions[key]);
  }

  public setDefinition(request: ISetNodeTypeDefinitionParams): void {
    if (request.componentName.indexOf("Node") === -1) {
      throw new Error("Cannot set definition for " + request.componentName + ", which is not a node type component.");
    }

    let id = request.componentName.replace("Node", "");
    id = id.charAt(0).toLowerCase() + id.slice(1);
    let readableId = id.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
    readableId = readableId.charAt(0).toUpperCase() + readableId.slice(1);

    let controlInputs: IControlFlowParam[] = request.controlInputs ?? [];
    if (
      !request.controlInputs &&
      !request.controlOutputOnly &&
      (request.controlInputOnly || request.controlOutputs?.length > 0)
    ) {
      controlInputs = [{ name: "control" }];
    }

    let definition: INodeTypeDefinition = {
      componentName: request.componentName,
      id: id,
      category: request.category,
      menuOrder: Object.keys(this.definitions).length,
      label: request.label ?? readableId,
      hideLabelOnAnchor: request.hideLabelOnAnchor ?? false,
      unconnectedAnchorLocation: request.unconnectedAnchorLocation,
      controlInputOnly: request.controlInputOnly ?? false,
      controlInputs: controlInputs,
      controlOutputOnly: request.controlOutputOnly ?? false,
      skipEvaluate: request.skipEvaluate ?? false,
      controlOutputs: request.controlOutputs,
      innerLoop: request.innerLoop ?? false,
      dataInputs: request.dataInputs,
      listToItem: request.listToItem ?? false,
      documentDataInputListName: request.documentDataInputListName,
      dataOutputs: request.dataOutputs,
      documentDataOutputList: request.documentDataOutputList,
      outputsSchemaProperties: request.outputsSchemaProperties ?? false,
      nodeConfigProperties: request.nodeConfigProperties ?? [],
      isExperimental: request.isExperimental ?? false,
      size: request.size ?? NodeSize.Normal,
      confirmDeletion: request.confirmDeletion ?? false,
      evaluate: request.evaluate
    };

    definition.controlOutputs.forEach((o) => {
      if (o.label === undefined) {
        let readableName = o.name.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
        readableName = readableName.charAt(0).toUpperCase() + readableName.slice(1);
        o.label = readableName;
      }
      if (o.isError === undefined) {
        o.isError = false;
      }
    });

    definition.dataInputs.forEach((i) => {
      if (i.enabled === undefined) {
        i.enabled = true;
      }
      if (i.required === undefined) {
        i.required = true;
      }
    });

    definition.dataOutputs.forEach((o) => {
      if (o.enabled === undefined) {
        o.enabled = true;
      }
      if (o.required === undefined) {
        o.required = true;
      }
    });

    this.definitions[definition.id] = definition;
  }
}

globalThis.nodeTypeDefinitions = new NodeTypeDefinitions();
