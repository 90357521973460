import { ReactFlowInstance, Edge, UpdateNodeInternals, Node } from "reactflow";
import { IBannerContext } from "@plex/react-components";
import { IControlFlowParam, IDataParam, INodeTypeDefinition } from "../NodeTypes/Base";
import {
  IConnectionValidationResult,
  checkAndRemoveUnmappable,
  dataHandleIsInput,
  dataHandleIsOutput,
  deleteEdgesFromNodes,
  getDesignerControlEdge,
  getDesignerDataEdge,
  getNameFromControlHandleId,
  getNameFromDataHandleId,
  validateConnection
} from "../Util/EdgeUtil";
import { canAddNode, getAvailableConfigDataTypes, updateNodeDataProperties } from "../Util/NodeUtil";
import { DataType, convertToList, shouldConvertToList, isListType } from "../NodeTypes/TypeDefinitions";

export interface IDataTypeConfig {
  configProperty?: { propertyName: string; propertyValue: any };
  dataType: DataType;
}

export interface IAutoConnectMeta {
  label: string;
  nodeType: string;
  handleId: string;
  isOutput: boolean;
  text: string;
  configProperties?: IDataTypeConfig;
  onlyValidFromSecondaryImplicitConversion: boolean;
}

export class AddMenuNodePopulater {
  public static _createNodeNatural: (
    nodeType: string,
    category: string | null,
    api: string | null,
    apiAction: string | null,
    apiFamily: string | null,
    savedGroupName: string | null,
    x: number,
    y: number,
    useExistingId: string,
    properties?: any
  ) => any;
  public static _reactFlowInstance: ReactFlowInstance<any, any>;
  public static _bannerContext: IBannerContext;
  public static _reactFlowEdgeSetter: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
  public static _reactFlowUpdateNodeInternals: UpdateNodeInternals;
  public static _activeNodes: Node<any, string | undefined>[];

  public static nodeCreator(newNode: any) {
    this._reactFlowInstance.addNodes(newNode);
  }

  public static nodeCreatorAndLinker(
    node: any,
    otherParams: any,
    useExistingNode: boolean = false,
    properties: any = {}
  ): any {
    const newNode = useExistingNode
      ? node
      : this._createNodeNatural(node.type, "", "", "", "", "", node.position.x, node.position.y, node.id, properties);
    newNode.selected = true;

    if (!useExistingNode && otherParams?.handleId === "FlowErrorInHandle") {
      newNode.data.nodeProperties = {
        ...newNode.data.nodeProperties,
        noceConfigProperties: {},
        stopType: [
          {
            key: "Failure",
            value: "Failure"
          }
        ]
      };
    }

    if (!newNode) {
      console.log(`Node Creation failed for template ${node}`);
      return null;
    }

    updateNodeDataProperties(newNode);

    this._reactFlowInstance.addNodes(newNode);
    this._reactFlowUpdateNodeInternals(newNode);

    return newNode;
  }

  public static getValidNodeTypesForGivenHandle(
    fromNodeId: string,
    fromNodeType: string,
    fromHandleId: string,
    experimentalModeState: boolean,
    reactFlowInstance: ReactFlowInstance
  ): Map<string, IAutoConnectMeta> {
    let entries: IAutoConnectMeta[] = [];
    const fakeNewNodeId = "NEW_NODE_1";
    const nodeDefinitions = globalThis.nodeTypeDefinitions
      .getDefinitions()
      .filter((d: INodeTypeDefinition) => !d.isExperimental || experimentalModeState);

    nodeDefinitions.forEach((nodeDefinition: INodeTypeDefinition) => {
      if (!canAddNode(nodeDefinition.id, reactFlowInstance)) {
        return;
      }

      const handleConfigs: { [handleId: string]: IDataTypeConfig } = {};

      const getHandleRank = (validationResult: IConnectionValidationResult) => {
        if (validationResult.onlyValidFromSecondaryImplicitConversion) {
          return 2;
        }
        if (validationResult.onlyValidFromImplicitConversion) {
          return 1;
        }
        return 0;
      };

      let toNodeInputHandleIds = nodeDefinition.controlInputs.map((controlInput: IControlFlowParam) => {
        return getDesignerControlEdge(
          controlInput.name,
          fakeNewNodeId,
          getNameFromControlHandleId(fromHandleId),
          fromNodeId
        ).sourceHandle;
      });

      const getAvailableDataTypes = (dataParam: IDataParam) => {
        let availableDataTypes: IDataTypeConfig[] = [{ dataType: dataParam.type }];
        // Only add type options for non-reference types,
        //   since we're including reference data props for association only and not to dynamically change their type.
        if (dataParam.type !== DataType.OBJECTREFERENCE) {
          const typesFromConfig = getAvailableConfigDataTypes(nodeDefinition.id, dataParam.name);
          availableDataTypes = availableDataTypes
            .filter(
              (config: IDataTypeConfig) =>
                !typesFromConfig.some((typeFromConfig: IDataTypeConfig) => typeFromConfig.dataType === config.dataType)
            )
            .concat(typesFromConfig);
        }

        return availableDataTypes;
      };

      if (dataHandleIsOutput(fromHandleId)) {
        nodeDefinition.dataInputs.forEach((dataInput: IDataParam) => {
          const availableDataTypes = getAvailableDataTypes(dataInput);
          availableDataTypes.forEach((availableType: IDataTypeConfig) => {
            const hasListOptions = availableDataTypes.some(
              (config: IDataTypeConfig) => config.configProperty && isListType(config.dataType)
            );
            let dataType = availableType.dataType as DataType;
            if (shouldConvertToList(availableType.dataType, dataInput, hasListOptions)) {
              dataType = convertToList(availableType.dataType);
            }
            const toHandleId = getDesignerDataEdge(
              { name: dataInput.name, type: dataType },
              dataType,
              fakeNewNodeId,
              getNameFromDataHandleId(fromHandleId),
              fromNodeId
            ).sourceHandle;
            toNodeInputHandleIds.push(toHandleId);
            handleConfigs[toHandleId] = { ...availableType, dataType: dataType };
          });
        });
      }
      const connectableInputHandleIds = toNodeInputHandleIds
        .map((toNodeHandleId: string) => {
          return {
            ...validateConnection(
              {
                source: fakeNewNodeId,
                sourceHandle: toNodeHandleId,
                target: fromNodeId,
                targetHandle: fromHandleId
              },
              nodeDefinition.id,
              fromNodeType,
              reactFlowInstance
            ),
            handleId: toNodeHandleId
          };
        })
        .filter((validation: IConnectionValidationResult) => validation.valid && !validation.requiredDataConfig)
        .sort((a: IConnectionValidationResult, b: IConnectionValidationResult) => getHandleRank(a) - getHandleRank(b));
      if (connectableInputHandleIds.length > 0) {
        const handleId = connectableInputHandleIds[0]!.handleId;
        entries.push({
          label: nodeDefinition.label,
          nodeType: nodeDefinition.id,
          handleId: handleId,
          isOutput: false,
          text: nodeDefinition.label,
          configProperties: handleConfigs[handleId],
          onlyValidFromSecondaryImplicitConversion:
            connectableInputHandleIds[0]!.onlyValidFromSecondaryImplicitConversion
        });
      }

      let toNodeOutputHandleIds = nodeDefinition.controlOutputs.map((controlOutput: IControlFlowParam) => {
        return getDesignerControlEdge(
          getNameFromControlHandleId(fromHandleId),
          fromNodeId,
          controlOutput.name,
          fakeNewNodeId
        ).targetHandle;
      });
      if (dataHandleIsInput(fromHandleId)) {
        nodeDefinition.dataOutputs.forEach((dataOutput: IDataParam) => {
          const availableDataTypes = getAvailableDataTypes(dataOutput);
          availableDataTypes.forEach((availableType: IDataTypeConfig) => {
            const hasListOptions = availableDataTypes.some(
              (config: IDataTypeConfig) => config.configProperty && isListType(config.dataType)
            );
            let dataType = availableType.dataType as DataType;
            if (shouldConvertToList(availableType.dataType, dataOutput, hasListOptions)) {
              dataType = convertToList(availableType.dataType);
            }
            const toHandleId = getDesignerDataEdge(
              { name: getNameFromDataHandleId(fromHandleId), type: dataType },
              dataType,
              fromNodeId,
              dataOutput.name,
              fakeNewNodeId
            ).targetHandle;
            toNodeOutputHandleIds.push(toHandleId);
            handleConfigs[toHandleId] = { ...availableType, dataType: dataType };
          });
        });
      }
      const connectableOutputHandleIds = toNodeOutputHandleIds
        .map((toNodeHandleId: string) => {
          return {
            ...validateConnection(
              {
                source: fromNodeId,
                sourceHandle: fromHandleId,
                target: fakeNewNodeId,
                targetHandle: toNodeHandleId
              },
              fromNodeType,
              nodeDefinition.id,
              reactFlowInstance
            ),
            handleId: toNodeHandleId
          };
        })
        .filter((validation: IConnectionValidationResult) => validation.valid && !validation.requiredDataConfig)
        .sort((a: IConnectionValidationResult, b: IConnectionValidationResult) => getHandleRank(a) - getHandleRank(b));
      if (connectableOutputHandleIds.length > 0) {
        const handleId = connectableOutputHandleIds[0]!.handleId;
        entries.push({
          label: nodeDefinition.label,
          nodeType: nodeDefinition.id,
          handleId: handleId,
          isOutput: true,
          text: nodeDefinition.label,
          configProperties: handleConfigs[handleId],
          onlyValidFromSecondaryImplicitConversion:
            connectableOutputHandleIds[0]!.onlyValidFromSecondaryImplicitConversion
        });
      }
    });

    entries = entries.sort(
      (a: IAutoConnectMeta, b: IAutoConnectMeta) =>
        this.getRank(a.nodeType, a.onlyValidFromSecondaryImplicitConversion) -
        this.getRank(b.nodeType, b.onlyValidFromSecondaryImplicitConversion)
    );

    const result: Map<string, IAutoConnectMeta> = new Map<string, IAutoConnectMeta>();
    entries.forEach((entry: IAutoConnectMeta) => result.set(entry.label, entry));

    return result;
  }

  private static getRank = (nodeType: string, onlyValidFromSecondaryImplicitConversion: boolean) => {
    const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(nodeType)!;
    const nodeTypeCount = globalThis.nodeTypeDefinitions.getDefinitions().length + 1;
    const implicitConversionRank = (onlyValidFromSecondaryImplicitConversion ? 1 : 0) * nodeTypeCount;
    return nodeDefinition.menuOrder + implicitConversionRank;
  };

  public static deleteNode(deletingNodes: [any]) {
    const deletingEdges = this._reactFlowInstance
      .getEdges()
      .filter((e: Edge) => deletingNodes.some((n: Node<any>) => n.id === e.source || n.id === e.target));
    checkAndRemoveUnmappable(
      deletingEdges.map((edge: Edge) => {
        return { edge: edge, newSchema: undefined };
      }),
      () => {
        this._reactFlowInstance.setNodes((nds) =>
          nds.filter((n) => deletingNodes.filter((dn: any) => n.id === dn.id).length === 0)
        );
        deleteEdgesFromNodes(
          deletingNodes.map((n: Node<any>) => n.id),
          this._reactFlowInstance,
          this._reactFlowUpdateNodeInternals
        );
      },
      this._reactFlowInstance,
      this._reactFlowUpdateNodeInternals
    );

    return true;
  }
}
